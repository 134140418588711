import service from "@/store/services/dashboard-service";
import serviceADX from "@/helpers/service";

const state = {
  isLoading: false,
  isDashboardLoading: true,
  dashboardData: [],
  dashboardSectionOne: [],
  dashboardSectionFour: [],
  dashboardVehcileStatus: [],
  dashboardVehcileTrips: [],
  fuelCost: {},
  electricCost: {},
  saving: {},
  soc: {},
  activeTrips: [],
  dashboardSortData: [],
  selectedCards: [],
  dashboardCardOptions: [],
  tripBreadCrumb: null,
  tripReset: null,
};

const mutations = {
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  },
  SET_DASHBOARD_SECTION_ONE(state, payload) {
    state.dashboardSectionOne = payload;
  },
  SET_DASHBOARD_SECTION_FOUR(state, payload) {
    state.dashboardSectionFour = payload;
  },
  SET_DASHBOARD_VEHICLE_STATUS(state, payload) {
    state.dashboardVehcileStatus = payload;
  },
  SET_DASHBOARD_VEHICLE_TRIPS(state, payload) {
    state.dashboardVehcileTrips = payload;
  },
  SET_DASHBOARD_LOADER(state, value) {
    state.isDashboardLoading = value;
  },
  SET_ACTIVE_TRIPS(state, payload) {
    state.activeTrips = payload;
  },
  SET_SECTION_OPTIONS(state, payload) {
    state.dashboardCardOptions = payload;
  },
  SET_SELECTED_CARD(state, payload) {
    state.selectedCards = payload.filter((r) => r.status == "on");
  },
  SET_DASHBOARD_SORT_DATA(state, payload) {
    state.dashboardSortData = payload;
  },
  SET_TRIPS_BREADCRUMB(state, data) {
    state.tripBreadCrumb = data;
  },
  SET_TRIPS_RESET(state, data) {
    state.tripReset = data;
  },
  SET_FUEL_COST(state, data) {
    state.fuelCost = data;
  },
  SET_ELECTRIC_COST(state, data) {
    state.electricCost = data;
  },
  SET_SAVING(state, data) {
    state.saving = data;
  },
  SET_SOC(state, data) {
    state.soc = data;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoading = true;
    service.dashboardData(params).then((data) => {
      store.commit("SET_DASHBOARD_DATA", data.body.data);
      store.state.isLoading = false;
    });
  },
  sectionOneList: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/first-section", params)
      .then((data) => {
        store.commit("SET_DASHBOARD_SECTION_ONE", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  sectionFourList: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/fourth-section", params)
      .then((data) => {
        store.commit("SET_DASHBOARD_SECTION_FOUR", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  vechicleStatusList: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-status", params)
      .then((data) => {
        store.commit("SET_DASHBOARD_VEHICLE_STATUS", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  vechicleTrips: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-trips", params)
      .then((data) => {
        store.commit("SET_DASHBOARD_VEHICLE_TRIPS", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  dashboardFuelCost: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-fuel-cost", params)
      .then((data) => {
        store.commit("SET_FUEL_COST", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  dashboardElectricCost: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-electric-cost", params)
      .then((data) => {
        store.commit("SET_ELECTRIC_COST", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  dashboardSaving: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-savings-cost", params)
      .then((data) => {
        store.commit("SET_SAVING", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  dashboardSoc: (store, params) => {
    store.state.isLoading = true;
    return serviceADX
      .get("dashboard-management/vehicle-soc", params)
      .then((data) => {
        store.commit("SET_SOC", data.body.data);
        return data.body.data;
      })
      .finally(() => (store.state.isLoading = false));
  },
  setActiveTrips: (store, params) => {
    store.state.isLoading = true;
    service.activeTripsDetail(params).then((data) => {
      store.commit("SET_ACTIVE_TRIPS", data.body.data);
      store.state.isLoading = false;
    });
  },
  setDashboardLoader: (store, value) => {
    store.commit("SET_DASHBOARD_LOADER", value);
  },
  dashboardCardOptions: (store, params) => {
    store.state.isLoading = true;
    service.dashboardCardOptions(params).then((data) => {
      store.commit("SET_SECTION_OPTIONS", data.body.data);
      store.commit("SET_SELECTED_CARD", data.body.data);
      store.state.isLoading = false;
    });
  },

  userDashboardCards: (store, params) => {
    store.state.isLoading = true;
    return service.userDashboardCards(params).then((data) => {
      store.commit("SET_DASHBOARD_SORT_DATA", data.body);
      store.state.isLoading = false;
      return data.body;
    });
  },

  updateCard: (store, params) => {
    // store.state.isLoading = true;
    service.updateCard(params).then(() => {
      store.dispatch("dashboardCardOptions", {
        vehicle_tech: params.vehicle_tech,
        tenant_tech: params.tenant_tech,
      });
      store.dispatch("userDashboardCards", {
        vehicle_tech: params.vehicle_tech,
        tenant_tech: params.tenant_tech,
      });
      // store.state.isLoading = false;
    });
  },
  setTripBreadCrumb: (store, value) => {
    store.commit("SET_TRIPS_BREADCRUMB", value);
  },
  setTripReset: (store, value) => {
    store.commit("SET_TRIPS_RESET", value);
  },
};

const getters = {
  getSavingCost(state) {
    return state.saving;
  },
  getSoc(state) {
    return state.soc;
  },
  getFuelCost(state) {
    return state.fuelCost;
  },
  getElectricCost(state) {
    return state.electricCost;
  },
  getTripBreadCrumb(state) {
    return state.tripBreadCrumb;
  },
  getTripReset(state) {
    return state.tripReset;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getDashoardLoading(state) {
    return state.isDashboardLoading;
  },

  getDashboadData(state) {
    return state.dashboardData;
  },

  getDashboadSectionOne(state) {
    return state.dashboardSectionOne;
  },

  getDashboadSectionFour(state) {
    return state.dashboardSectionFour;
  },

  getVechileStatus(state) {
    return state.dashboardVehcileStatus;
  },
  getVechileTrips(state) {
    return state.dashboardVehcileTrips;
  },
  getActiveTrips(state) {
    return state.activeTrips;
  },
  //
  getSelectedCards(state) {
    return state.selectedCards;
  },
  getCardOptions(state) {
    return state.dashboardCardOptions;
  },
  getDashboadSortData(state) {
    return state.dashboardSortData;
  },
};

const dashboardData = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dashboardData;
