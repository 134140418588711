import auth from "../middleware/auth";
import guest from "../middleware/guest";
import Vue from "vue";

const routes = [
  {
    name: "login",
    path: "/login",
    meta: { middleware: guest },
    component: () => import("@/views/auth/Login"),
  },
  {
    path: "/",
    component: () => import("@/views/dashboard/MainLayout.vue"),
    meta: { middleware: auth },
    children: [
      // With Permission
      // Dashboard
      ...(Vue.prototype.$admin.hasAccessTo("dashboard.list")
        ? [
            {
              name: "dashboard",
              path: "/",
              meta: { middleware: auth },
              component: () => import("@/views/modules/dashboard/Index.vue"),
            },
          ]
        : []),
      // Dashboard

      //Modules Screen:
      // Make
      ...(Vue.prototype.$admin.hasAccessTo("md-makes.list")
        ? [
            {
              name: "md_makes",
              path: "/md-makes",
              meta: { middleware: auth },
              component: () => import("@/views/modules/md-makes/Index.vue"),
            },
          ]
        : []),
      // Models
      ...(Vue.prototype.$admin.hasAccessTo("md-models.list")
        ? [
            {
              name: "md_models",
              path: "/md-models",
              meta: { middleware: auth },
              component: () => import("@/views/modules/md-models/Index"),
            },
          ]
        : []),
      // Vehicle
      ...(Vue.prototype.$admin.hasAccessTo("vehicles.list")
        ? [
            {
              name: "vehicles",
              path: "/vehicles",
              meta: { middleware: auth },
              component: () => import("@/views/modules/vehicles/Index.vue"),
            },
          ]
        : []),

      // Vehicle Telematic
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-telematics.list")
        ? [
            {
              name: "vehicle_telematics",
              path: "/vehicle-telematics",
              meta: { middleware: auth },
              component: () => import("@/views/modules/telematics/Index.vue"),
            },
          ]
        : []),
      // Detail
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-telematics-detail.list")
        ? [
            {
              name: "telematic_details",
              path: "/vehicle-telematics/:imei",
              meta: { middleware: auth, parent_name: ["vehicle_telematics"] },
              component: () => {
                const authUser = JSON.parse(localStorage.getItem("user"));
                if (authUser.isAdmin) {
                  return import(
                    "@/views/modules/telematics/sub-screen/Detail.vue"
                  );
                } else {
                  return import(
                    "@/views/modules/telematics/sub-screen/OEMDetail.vue"
                  );
                }
              },
            },
          ]
        : []),

      // Trip Listing
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-trips.list")
        ? [
            {
              name: "trips",
              path: "/trips",
              meta: { middleware: auth },
              component: () => import("@/views/modules/trips/Index"),
            },
          ]
        : []),
      // Single Trip List
      ...(Vue.prototype.$admin.hasAccessTo("single-vehicle-trips.list")
        ? [
            {
              name: "trip-listing",
              path: "/trip-listing/:imei",
              meta: { middleware: auth, parent_name: ["trips"] },
              component: () =>
                import(
                  "@/views/modules/trips/sub-screen/SingleTripListing.vue"
                ),
            },
          ]
        : []),
      // Trip Details
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-trip-details.list")
        ? [
            {
              name: "trip-detail",
              path: "/trip-detail/:id",
              meta: {
                middleware: auth,
                parent_name: ["trips", "trip-listing"],
              },
              component: () =>
                import("@/views/modules/trips/sub-screen/TripDetails.vue"),
            },
          ]
        : []),
      // Charge Session
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-charge-sessions.list")
        ? [
            {
              name: "charge_session",
              path: "/charge-session",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/charge-session/Index.vue"),
            },
          ]
        : []),
      // Charge Session List
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-charge-sessions.list")
        ? [
            {
              name: "charge-session-list",
              path: "/charge-session-list/:imei",
              meta: { middleware: auth, parent_name: ["charge_session"] },
              component: () =>
                import(
                  "@/views/modules/charge-session/sub-screen/ChargeSession.vue"
                ),
            },
          ]
        : []),

      // Battery Diagnostics
      ...(Vue.prototype.$admin.hasAccessTo("battery-diagnostics.list")
        ? [
            {
              name: "battery_diagnostics",
              path: "/battery-diagnostics",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/battery-diagnostics/Index.vue"),
            },
          ]
        : []),
      // battery Diagnostic
      // old permission: vehicle-telematics.list
      ...(Vue.prototype.$admin.hasAccessTo("battery-diagnostics.list")
        ? [
            {
              name: "battery_diagnostics_list",
              path: "/battery-diagnostics-list/:imei",
              meta: { middleware: auth, parent_name: ["battery_diagnostics"] },
              component: () =>
                import(
                  "@/views/modules/battery-diagnostics/sub-screen/BatteryDiagnosticsList.vue"
                ),
            },
          ]
        : []),
      // Remote On Off
      ...(Vue.prototype.$admin.hasAccessTo("vehicles.remote")
        ? [
            {
              name: "remote_on_off",
              path: "/vehicle-activity",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/remote-on-off/Index.vue"),
            },
          ]
        : []),

      // Vehicle Maintenance Logs
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-maintenance-logs.list")
        ? [
            {
              name: "vehicle_maintenance_logs",
              path: "/vehicle-maintenance-logs",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/vehicle-maintenance/maintenance-logs/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("upcoming-vehicle-maintenance.list")
        ? [
            {
              name: "upcoming_vehicle_maintenance",
              path: "/upcoming-vehicle-maintenance",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/vehicle-maintenance/upcoming-maintenance/Index.vue"
                ),
            },
          ]
        : []),
      //Modules Screen >

      // Performance
      ...(Vue.prototype.$admin.hasAccessTo("vehicle-comparision.list")
        ? [
            {
              name: "vehicle_comparison",
              path: "/vehicle-comparison",
              meta: { middleware: auth },
              // component: () => import("@/views/dashboard/performance/VehicleComparison.vue"),
              component: () =>
                import("@/views/modules/performance/VehicleComparison.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("driver-comparision.list")
        ? [
            {
              name: "driver_comparison",
              path: "/driver-comparison",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/performance/DriverComparison.vue"),
            },
          ]
        : []),
      // Performance

      // Route Tracking
      ...(Vue.prototype.$admin.hasAccessTo("route-tracking.list")
        ? [
            {
              name: "route-optimization",
              path: "/route-optimization",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/route-optimization/Index.vue"),
            },
          ]
        : []),
      // Route Listing
      ...(Vue.prototype.$admin.hasAccessTo("routes.list")
        ? [
            {
              name: "route_listing",
              path: "/route-listing",
              meta: { middleware: auth },
              component: () => import("@/views/modules/routing/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("routes.create")
        ? [
            {
              name: "route-create",
              path: "/route-listing-create",
              meta: { middleware: auth, parent_name: ["route-listing"] },
              component: () =>
                import("@/views/modules/routing/sub-screen/RouteCreate.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("routes.edit")
        ? [
            {
              name: "route-edit",
              path: "/route-listing-edit/:id",
              meta: { middleware: auth, parent_name: ["route-listing"] },
              component: () =>
                import("@/views/modules/routing/sub-screen/RouteEdit.vue"),
            },
          ]
        : []),
      // Route Listing
      // Route Planing
      ...(Vue.prototype.$admin.hasAccessTo("route-planning.list")
        ? [
            {
              name: "route_planning",
              path: "/route-planning",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/route-planing/Index.vue"),
            },
            {
              name: "job_route",
              path: "/job-route/:id",
              meta: { middleware: auth, parent_name: ["route-planning"] },
              component: () =>
                import("@/views/modules/route-planing/sub-screen/JobRoute.vue"),
            },
          ]
        : []),

      // Route Planing

      ...(Vue.prototype.$admin.hasAccessTo("routes.comparision")
        ? [
            {
              name: "route_comparison",
              path: "/route-comparison",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/route-comparison/Index.vue"),
            },
          ]
        : []),

      // Charging Management
      ...(Vue.prototype.$admin.hasAccessTo("charging-dashboard.list")
        ? [
            {
              name: "charging_dashboard",
              path: "/charging-dashboard",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/charging-dashboard/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("charging-dashboard.list")
        ? [
            {
              name: "charging_dashboard_detail",
              path: "/charging-dashboard/detail",
              meta: {
                middleware: auth,
                parent_name: ["charging_dashboard"],
              },
              component: () =>
                import(
                  "@/views/modules/charging-management/charging-dashboard/sub-screen/Details.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("operations-troubleshooting.list")
        ? [
            {
              name: "operations_troubleshooting",
              path: "/operations-troubleshooting",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/operations-troubleshooting/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("operations-troubleshooting.list")
        ? [
            {
              name: "operations_troubleshooting_detail",
              path: "/operations-troubleshooting/detail",
              meta: {
                middleware: auth,
                parent_name: ["operations_troubleshooting"],
              },
              component: () =>
                import(
                  "@/views/modules/charging-management/operations-troubleshooting/sub-screen/Details.vue"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("payment-billing.list")
        ? [
            {
              name: "payment_billing",
              path: "/payment-billing",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/payment-billing/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("locations.list")
        ? [
            {
              name: "locations",
              path: "/locations",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/locations/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("rates.list")
        ? [
            {
              name: "rates",
              path: "/rates",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/charging-management/rates/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("charge-stations.list")
        ? [
            {
              name: "charge_stations",
              path: "/charge_stations",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/charge-stations/Index.vue"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("bookings.list")
        ? [
            {
              name: "bookings",
              path: "/bookings",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/charging-management/bookings/Index.vue"
                ),
            },
          ]
        : []),
      // Charging Management

      // Report
      ...(Vue.prototype.$admin.hasAccessTo("journey-reports.list")
        ? [
            {
              name: "journey_reports",
              path: "/journey-reports",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/reports/journey-reports/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("daily-mileage-reports.list")
        ? [
            {
              name: "daily_mileage_reports",
              path: "/daily-mileage-reports",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/reports/daily-mileage-reports/Index.vue"
                ),
            },
          ]
        : []),
      // Report

      // Flespi
      ...(Vue.prototype.$admin.hasAccessTo("flespi-streams.list")
        ? [
            {
              name: "streams",
              path: "/streams",
              meta: { middleware: auth },
              component: () => import("@/views/modules/flespi/Streams.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("flespi-plugins.list")
        ? [
            {
              name: "plugins",
              path: "/plugins",
              meta: { middleware: auth },
              component: () => import("@/views/modules/flespi/Plugins.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("flespi-calcs.list")
        ? [
            {
              name: "calculators",
              path: "/calculators",
              meta: { middleware: auth },
              component: () => import("@/views/modules/flespi/Calculators.vue"),
            },
          ]
        : []),

      // Flespi

      // Pages
      ...(Vue.prototype.$admin.hasAccessTo("role")
        ? [
            {
              name: "roles",
              path: "roles",
              meta: { middleware: auth },
              component: () => import("@/views/modules/roles/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("user")
        ? [
            {
              name: "users",
              path: "/users",
              meta: { middleware: auth },
              component: () => import("@/views/modules/users/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("driving-staff.list")
        ? [
            {
              name: "driving_staff",
              path: "/driving-staff",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/driving-staff/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("staff-assignment.list")
        ? [
            {
              name: "staff_assignment",
              path: "/staff_assignment",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/staff-assignment/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("oem-customers.list")
        ? [
            {
              name: "oem_customers",
              path: "/oem-customers",
              meta: { middleware: auth },
              component: () => import("@/views/modules/customer/Index"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("oem-clients.list")
        ? [
            {
              name: "oem-clients.index",
              path: "/oem-clients/index",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/oem-clients/Index"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("iot-device-models.list")
        ? [
            {
              name: "iot_device_providers",
              path: "/iot-device-models",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/iot-device-models/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("iot-devices.list")
        ? [
            {
              name: "iot_devices",
              path: "/iot-devices",
              meta: { middleware: auth },
              component: () => import("@/views/modules/iot-devices/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("data-sims.list")
        ? [
            {
              name: "data_sims",
              path: "/data-sims",
              meta: { middleware: auth },
              component: () => import("@/views/modules/data-sims/Index.vue"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("batteries.list")
        ? [
            {
              name: "batterries",
              path: "/batteries",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/battery-management/Index"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("oem-b2b.list")
        ? [
            {
              name: "oem_business",
              path: "/oem-business",
              meta: { middleware: auth },
              component: () => import("@/views/modules/oem-business/Index.vue"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("tenants.list")
        ? [
            {
              name: "tenants_management",
              path: "/tenants-management",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/tenants-management/Index.vue"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("tenant-configurations.list")
        ? [
            {
              name: "tenant_configurations",
              path: "/tenant-configurations",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/tenant-configurations/Index.vue"),
            },
          ]
        : []),

      // Alert and notification
      ...(Vue.prototype.$admin.hasAccessTo("alerts.list")
        ? [
            {
              name: "alerts",
              path: "/alerts",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/modules/alerts-and-notifications/alerts/Index.vue"
                ),
            },
          ]
        : []),
      // this is not Permission based
      {
        name: "notifications",
        path: "/notifications",
        meta: { middleware: auth },
        component: () =>
          import(
            "@/views/modules/alerts-and-notifications/notifications/Index.vue"
          ),
      },
      // ...(Vue.prototype.$admin.hasAccessTo("alerts.list")
      //   ? [
      //       {
      //         name: "notifications",
      //         path: "/notifications",
      //         meta: { middleware: auth },
      //         component: () =>
      //           import(
      //             "@/views/modules/alerts-and-notifications/notifications/Index.vue"
      //           ),
      //       },
      //     ]
      //   : []),

      //  Help and Support

      ...(Vue.prototype.$admin.hasAccessTo("help-support.list")
        ? [
            {
              name: "support_tickets",
              path: "/support-tickets",
              meta: { middleware: auth },
              component: () =>
                import("@/views/modules/help-and-support/Index.vue"),
            },
          ]
        : []),
    ],
  },
];

export default routes;
